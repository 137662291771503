// Load Bootstrap JS
import bootstrap from "bootstrap";
import AOS from 'aos';

// Load Styles
import "../../node_modules/aos/dist/aos.css";
import "../scss/main.scss";

// App code
AOS.init({
  once: true,
  duration: 1000
});
